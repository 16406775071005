#form {
    width: 100%;
    margin: 91px 0;
}

#form .inner {
    width: 80%;
    margin: 0 auto;
    display: flex;
    min-height: 330px;
}

#form .left {
    width: 50%;
    padding: 60px 6%;
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#form .right {
    width: 50%;
    background: radial-gradient(128.61% 138.15% at 128.61% -27.71%, #38877B 0%, #09183B 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        , #000000;
    backdrop-filter: blur(28.5px);
    /* background: url("../../images/form.png") rgba(179, 179, 179, 0.2); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#form h2 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
    margin-bottom: 20px;
}

#form p {
    font-family: "K_Gotham Pro";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: #A4ABB3;
    opacity: 0.8;
}

#form form {
    width: 100%;
    height: calc(100% + 1px);
    padding: 60px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(24.5px);
}

#form form>.text {
    display: flex;
    flex-direction: column;
}

#form label {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    text-transform: lowercase;
    color: #F6F6F6;

    opacity: 0.5;
}

#form input[type="text"] {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-transform: capitalize;
    color: #FFFFFF;
    border-bottom: 0.5px solid #F6F6F6;
    border-radius: 0;
    margin-top: 5px;
}

#form button {
    font-family: 'K_Gotham Pro';
    padding: 15px 30px;
    background-color: #38877B;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    transition: 0.2s;
}

#form .arrow {
    transition: 0.2s;
}

#form button:hover .arrow {
    transform: translateX(5px);
    color: #E76B47;
}

#form label>input[type="checkbox"] {
    display: none;
}

#form label>input[type="checkbox"]+div {
    display: flex;
    align-items: center;
    gap: 10px;
}

#form label>input[type="checkbox"]+div::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 14px;
    height: 14px;
    border-radius: 10%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
}

#form label>input[type="checkbox"]:checked+*::before {
    content: "✓";
    color: white;
    text-align: center;
}

#form input[type="text"]::placeholder {
    text-transform: none;
    font-weight: 300;
    color: white;
    opacity: 0.5;
}

#form .arrow {
    margin-left: 10px;
}

#form .error {
    height: 15px;
    color: rgb(255, 119, 119);
    font-size: 11px;
    margin-top: 4px;
    opacity: 1;
}

@media screen and (min-width: 1281px) {
    #form .inner {
        width: 70%;
    }
}

@media screen and (max-width: 1024px) {
    #form h2 {
        font-size: 24px;
    }
}

@media screen and (max-width: 940px) {
    #form .inner {
        width: 100%;
        padding: 0 20px;
    }
}

@media screen and (max-width: 768px) {
    #form .inner {
        width: 100%;
        padding: 0 20px;
        flex-direction: column;
    }

    #form .inner>div {
        width: 100%;
    }

    #form .left {
        text-align: center;
        padding: 70px 30px 70px;
        min-height: 320px;
    }

    #form form {
        padding: 40px 30px 50px;
    }

    #form button {
        margin-left: 0;
    }

    
}