#progress {
    margin-bottom: 150px;
    .inner {
        width: 70%;
        margin: 0 auto;
    }
}

.progress {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-item {
            &-left {
                padding-right: 150px;
            }
            &-title {
                color: #09183b;
                font-family: "NAMU";
                font-size: 36px;
                font-style: normal;
                font-weight: 300;
                line-height: 120%; /* 43.2px */
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            &-text {
                color: #333;
                font-family: "TT Interfaces";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; /* 20.8px */
                margin-bottom: 20px;
                padding-right: 72px;
            }

            &-button {
                border-radius: 79px;
                background: #09183b;
                color: #fff;
                font-family: "TT Interfaces";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                border: unset;
                padding: 16px 36px 15px;
                margin-bottom: 50px;
            }
            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                transition: all 0.4s ease-in-out;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    &-status {
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 14px;

            span {
                color: #333;
                font-family: "TT Interfaces";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%; /* 20.8px */

                &:last-child {
                    color: #38877b;
                    text-align: right;
                    font-family: "K_Gotham Pro";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 21px */
                }
            }
        }
        .ant-progress {
            .ant-progress-inner {
                height: 20px;
                padding: 2px;

                .ant-progress-bg {
                    height: 16px !important;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    #progress {
        .inner {
            width: 80%;
        }
    }
}
@media (max-width: 1200px) {
    .progress {
        &-grid {
            display: flex;
            flex-direction: column;

            &-item {
                &-left {
                    margin-bottom: 50px;
                    padding-right: 0;
                }

                &-text {
                    width: 60%;
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .progress {
        &-grid {
            &-item {
                &-title {
                    font-size: 24px;
                }

                &-button {
                    font-size: 14px;
                }
            }
        }

        &-status {
            &-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 14px;

                span {
                    color: #333;
                    font-family: "TT Interfaces";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 130%; /* 20.8px */

                    &:last-child {
                        color: #38877b;
                        text-align: right;
                        font-family: "K_Gotham Pro";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 150%; /* 21px */
                    }
                }
            }
            .ant-progress {
                .ant-progress-inner {
                    height: 20px;
                    padding: 2px;

                    .ant-progress-bg {
                        height: 16px !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    #progress {
        margin-bottom: 150px;
        .inner {
            width: 100%;
            padding: 0 20px;
        }
    }
}
@media (max-width: 576px) {
    .progress {
        &-grid {
            &-item {
                &-text {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }
    }
}
