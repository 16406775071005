#video {
    position: relative;
    background: radial-gradient(139.26% 150.14% at -50.13% -72.7%, #38877B 0%, #09183B 100%), #FFFFFF;
}

#video h2 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 33px;
}

#video p {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #A4ABB3;
    margin-bottom: 15px;
}

#video .inner {
    width: 70%;
    margin: 0 auto;
    padding: 100px 0;
}

#video .video-con {
    margin-top: 63px;
}

#video .video-con {
    position: relative;
}

#video .video-con img {
    width: 100%;
}

#video .play {
    position: absolute;
    height: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: 1s;
}

#video .play:hover {
    height: 45%;
}

#video .par {
    height: 700px;
    position: absolute;
}

#video .bg {
    position: absolute;
    z-index: 0;
    top: -50px;
    /* height: 100%; */
    height: 1000px;
}

#video .iframe-con {
    width: 90%;
    height: 0;
    padding-bottom: 50.625%;
    position: relative;
}

#video .iframe-con iframe {
    width: 100%;
    height: 100%;
    position: absolute;
}

@media screen and (max-width: 728px) {
    #video .inner {
        width: 100%;
        padding: 100px 20px;
    }
    #video .play {
        height: 80%;
    }

    #video .inner h2 {
        font-size: 24px;
    }
}

@media screen and (min-width: 1200px) {
    #video .inner {
        width: 70%;
    }
}

