@font-face {
  font-family: 'NAMU';
  src: url('../src/fonts/NAMU-1750.ttf') format('truetype');
}

@font-face {
  font-family: 'K_Gotham Pro';
  src: url('../src/fonts/Gotham-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Interfaces';
  src: url('../src/fonts/TTInterfaces-Regular.ttf') format('truetype');
}

html {
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'TTInterface', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F6F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
}

section {
  overflow: hidden;
}

.fade-in-right {
  opacity: 0;
  animation: fade-in-right 2s ease-in-out both;
}

.fade-in-left {
  opacity: 0;
  animation: fade-in-left 2s ease-in-out both;
}

.fade-in-up {
  transform: translateY(-50px);
  opacity: 0;
  /* animation: fade-in-up 2s ease-in-out both; */
}

@keyframes fade-in-up{
  from {
    opacity: 1;
    transform: translateY(0); /* Adjust the vertical distance as needed */
  }

  to {
    opacity: 0;
    transform: translateY(-50px); /* Adjust the vertical distance as needed */
  }
}

.disable-scroll {
  overflow: hidden;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-20px); /* Adjust the vertical distance as needed */
  }

  to {
    opacity: 1;
    transform: translateX(0); /* Adjust the vertical distance as needed */
  }
}

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(20px); /* Adjust the vertical distance as needed */
  }

  to {
    opacity: 1;
    transform: translateX(0px); /* Adjust the vertical distance as needed */
  }
}

button {
  cursor: pointer;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}