#qa {
    width: 80%;
    margin: 0px auto;
    padding-bottom: 100px;
}

#qa .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

#qa .label div {
    display: flex;
    align-items: center;
}

#qa .label span {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    color: #09183B;
}

#qa .icon {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 5px;
    transition: transform 0.5s ease;
}

#qa .icon:hover {
    background-color: #eee;
}

#qa .ant-collapse-header.ant-collapse-icon-collapsible-only {
    align-items: center !important;
}

#qa .rotate {
    transform: rotate(-45deg);
}

#qa .content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 40px;
}

#qa .content .text {
    width: 100%;
    color: #09183b;
    font-size: 16px;
    font-family: "K_Gotham Pro";
    line-height: 1.55;
    font-weight: 300;
}

@media screen and (min-width: 1200px) {
    #qa {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 728px) {
    #qa {
        width: 100%;
        padding: 0 20px;
        padding-bottom: 100px;
    }
}