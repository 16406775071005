.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    /* pointer-events: none; */
}

.close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}