#all-in-one {
    width: 100%;
    padding: 100px 3% 180px;
    position: relative;
    background: radial-gradient(66.94% 90.45% at -22.29% -26.14%, #38877B 0%, #09183B 100%), #09183B;
}

#all-in-one .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
}

#all-in-one h2 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    -webkit-text-stroke: 1px;
    -webkit-text-fill-color: transparent;
}

#all-in-one p {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
}

#all-in-one .buttons {
    display: flex;
    gap: 20px;
}

#all-in-one button {
    width: 86px;
    height: 86px;
    border-radius: 43px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: white ;
}

#all-in-one button>* {
    width: 20px;
}

#all-in-one button:hover>* {
    color: #38877B;
}

#all-in-one .card {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 24px;
    /* width: 35vw; */
    /* width: 546px; */
}

#all-in-one .card-bg {
    /* width: 546px;
    height: 622px; */
    /* width: 100%;
    height: 50%; */
    width: 100%;
    padding-top: 120%;
    background-size: cover;
}

#all-in-one .slider {
    position: relative;
    display: flex;
    overflow-x: scroll;
    gap: 20px;
    cursor: grab;
}

#all-in-one .slider::-webkit-scrollbar {
    display: none;
}

#all-in-one .card h3 {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 20px 0;
}

#all-in-one .card p {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #F2F2F2;
    text-transform: none;
    width: 100%;
}

#all-in-one .bg {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 80%;
    right: 0;
}

@media screen and (max-width: 1200px) {
    /* #all-in-one .card-bg {
        width: 346px;
        height: 394px;
    } */
}

@media screen and (max-width: 728px) {
    #all-in-one {
        padding: 100px 3% 100px;
    }
    #all-in-one h2 {
        font-size: 32px;
        text-align: center;
    }
    #all-in-one .header div > p {
        font-size: 20px;
        text-align: center;
    }
    #all-in-one .header {
        flex-direction: column;
    }
    #all-in-one .buttons {
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }
    #all-in-one .card {
        flex: 0 0 80%;
    }
}