#gallery {
    width: 100%;
    padding: 150px 0;
}

#gallery .inner {
    width: 80%;
    margin: 0 auto;
}

#gallery h2 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
    margin-bottom: 20px;
}

#gallery .carousel {
    width: 100%;
}

#gallery .modal-carousel {
    width: 85vw;
}

#gallery .modal-carouse img {
    width: 100%;
    height: 100%;
}

#gallery .buttons {
    width: 250px;
    display: flex;
    margin-top: 40px;
}

#gallery button {
    width: 50%;
    border: none;
    padding: 14px 22px;
    outline: none;
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    cursor: pointer;
    border: 1px solid #919191;
    background-color: transparent;
    color: #919191;
}

#gallery button.active {
    color: #FFFFFF;
    background-color: #919191;
}

#gallery button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    
}
#gallery button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


#gallery .car-item {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    position: relative;
}

#gallery .car-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#gallery img {
    width: 100%;
    /* height: 592px; */
}

@media screen and (max-width: 728px) {
    #gallery {
        padding: 100px 0;
    } 
    #gallery .inner {
        width: 100%;
        padding: 0 20px;
    }

    #gallery h2 {
        font-size: 24px;
    }

    #gallery .buttons {
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    #gallery .inner {
        width: 70%;
        margin: 0 auto;
    }
}