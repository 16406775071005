#adv {
    width: 100%;
    padding-bottom: 100px;
}

#adv .inner {
    width: 80%;
    margin: 0 auto;
}

#adv h2 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
    margin-bottom: 40px;
}

#adv .label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

#adv .label div {
    display: flex;
    align-items: center;
}

#adv .label span {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
}

#adv .label .num {
    display: inline-block;
    min-width: 103px;
    color: #38877B;
}

#adv .icon {
    transition: transform 0.5s ease;
}

#adv .ant-collapse-header.ant-collapse-icon-collapsible-only {
    align-items: center !important;
}

#adv .rotate {
    transform: rotate(225deg);
}

#adv .content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 40px;
}

#adv .content .img {
    width: 50%;
    height: 400px;
    background-size: cover;
    background-position: center;
}

#adv .content .text {
    width: 50%;
    color: #09183b;
    font-size: 16px;
    font-family: "K_Gotham Pro";
    line-height: 1.55;
    font-weight: 400;
}

@media screen and (min-width: 1200px) {
    #adv .inner {
        width: 70%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 728px) {
    #adv {
        padding: 0;
    }
    #adv .inner {
        width: 100%;
        padding: 0 20px;
    }
    #adv .label span {
        font-size: 16px;
    }
    #adv .content {
        flex-direction: column;
    }
    #adv .content .img {
        width: 100%;   
        height: 230px;
    }
    #adv .content .text {
        width: 100%;
    }
    #adv .label .num {
        min-width: 40px;
        font-size: 24px;
    }
    #adv h2 {
        font-size: 24px;
    }
}