footer {
    background-color: #09183b;
    width: 100%;
    padding: 30px 0;
}

footer .inner {
    width: 80%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
}

footer .left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

footer .right {
    width: 60%;
    font-size: 14px;
}

footer .text {
    color: #919191;
    font-size: 10px;
    font-family: 'K_Gotham Pro', Arial, sans-serif;
    line-height: 1.2;
    font-weight: 400;
}

footer .links {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 10px;
}

footer .link {
    font-size: 14px;
    font-family: 'K_Gotham Pro', Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    text-decoration: none;
}

footer .phone a {
    color: #919191;
    font-size: 1em;
    font-family: 'K_Gotham Pro', Arial, sans-serif;
    font-weight: 400;
    text-decoration: none;
    margin-left: auto;
    display: block;
    text-align: right;
}

footer .adresses {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
}

footer .adress {
    width: 33%;
    color: #ffffff;
    font-size: 1em;
    font-family: 'K_Gotham Pro', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    opacity: 0.9;
}

footer .adresses h3 {
    width: 33%;
    color: #7b7d80;
    font-size: 1em;
    font-family: 'K_Gotham Pro', Arial, sans-serif;
    line-height: 22px;
    font-weight: 700;
}

footer .adress span {
    color: rgb(153, 153, 151);
}

footer .logo2 {
    width: 130px;
}

footer .logo1 {
    display: none;
}

@media screen and (max-width: 1024px) {
    footer .right {
        font-size: 14px;
    }

    footer .adress {
        width: 50%;
    }
}

@media screen and (max-width: 1200px) {
    footer .inner {
        width: 70%;
        margin: 0 auto;
        display: flex;
        gap: 40px;
    }
}

@media screen and (max-width: 768px) {
    footer .inner {
        flex-direction: column-reverse;
    }

    footer .left {
        width: 100%;
    }

    footer .right {
        width: 100%;
    }

    footer .links {
        justify-content: flex-start;
    }

    footer .phone a {
        text-align: left;
    }

    footer .logo2 {
        display: none;
    }

    footer .logo1 {
        width: 50%;
    }
}