#benefits {
    width: 100%;
}

#benefits .flex {
    display: flex;
    flex-wrap: wrap;
}

#benefits .flex>* {
    width: 100%;
}

#benefits .flex>div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#benefits .flex>div>div>div {
    width: 50%;
}

#benefits .flex>div>* {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#benefits h2 {
    /* width: 50%; */
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
    margin-bottom: 20px;
}

#benefits p {
    /* width: 50%; */
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #A4ABB3;
    flex: none;
    order: 1;
    flex-grow: 0;
}

#benefits img {
    width: 100%;
}



@media screen and (max-width: 1024px) {
    #benefits h2 {
        width: 100%;
        font-size: 30px;
    }
    
    #benefits p {
        width: 100%;
    }
    #benefits .flex>div>div>div {
        width: 80%;
    }
}

@media screen and (max-width: 728px) {
    #benefits .flex>div>* {
        width: 100%;
    }
    #benefits .flex>div {
        flex-direction: column;
    }
    #benefits .flex>*:nth-child(even) {
        flex-direction: column-reverse;
    }
    #benefits .flex>div>div {
        padding: 50px 0;
    }
    #benefits .flex>div>div:first-child {
        padding-top: 0;
    }
    
    #benefits p, #benefits h2 {
        width: 90%;
        text-align: center;
        margin: 0 auto;
    }

    #benefits .flex>div>div>div {
        width: 100%;
    }
}