#p404 {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #09183b;
  top: 0;
  left: 0;
  background: radial-gradient(#1d4757, #09183b);
}

#p404 .inner {
  position: relative;
  width: 100%;
  height: 100%;
}

#p404 .svg {
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0%;
  padding-bottom: 19.5%;
}

#p404 p {
  font-size: 16px;
  font-family: "K-Gotham Pro";
  color: #a4abb3;
  position: absolute;
  top: -70px;
  text-align: center;
}

#p404 .blub {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  /* filter: blur(250px); */
}

#p404 .bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

#p404 .bg:nth-child(1) {
  width: 25%;
}
#p404 .bg:nth-child(2) {
  width: 50%;
}
#p404 .bg:nth-child(3) {
  width: 75%;
}
#p404 .bg:nth-child(4) {
  width: 100%;
}

#p404 .gomain {
  position: absolute;
  width: 30%;
  right: 0;
  bottom: -30%;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  #p404 .svg {
    width: 60%;
    padding-bottom: 30%;
  }
}

@media screen and (max-width: 728px) {
  #p404 .svg {
    width: 90%;
    padding-bottom: 45%;
  }
  #p404 p {
    font-size: 12px;
  }
}
