#map {
    padding: 150px 0;
}

#map h2 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
}

#map button {
    width: 242px;
    height: 55px;
    border: 1px solid #09183B;
    border-radius: 10px;
    background-color: white;
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #09183B;
    cursor: pointer;
}

#map .flex {
    width: 85%;
    padding: 0;
    padding-bottom: 20px;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 728px) {
    #map .flex {
        flex-direction: column;
        padding: 0 20px 20px 20px;
        align-items: center;
        justify-content: center;
    }

    #map .flex h2 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 15px;
    }

    #map {
        padding: 60px 0;
    }
}

#map img {
    width: 70%;
}

@media screen and (min-width: 1200px) {
    #map .flex {
        width: 80%;
        padding: 0;
        padding-bottom: 20px;
        margin-left: 15%;
    }
}