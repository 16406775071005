#sub {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #09183b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: radial-gradient(#1d4757, #09183b);
}

#sub .inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#sub .bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  z-index: 0;
}

#sub h1 {
  color: #ffffff;
  font-size: 64px;
  font-family: "K-Gotham Pro", Arial, sans-serif;
  line-height: 1.05;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

#sub h2 {
  color: #ffffff;
  font-size: 24px;
  font-family: "K-Gotham Pro", Arial, sans-serif;
  line-height: 1.05;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
}

#sub .text {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

#sub p {
  font-size: 16px;
  font-family: "K-Gotham Pro";
  color: #a4abb3;
  text-align: center;
}

#sub .gomain {
  width: 35%;
  display: block;
  margin: 0 auto;
  /* padding: 30px; */
  cursor: pointer;
}

#sub .bg:nth-child(1) {
  width: 25%;
}

#sub .bg:nth-child(2) {
  width: 50%;
}

#sub .bg:nth-child(3) {
  width: 75%;
}

#sub .bg:nth-child(4) {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  #sub .svg {
    width: 60%;
    padding-bottom: 30%;
  }
}

@media screen and (max-width: 728px) {
  #sub .svg {
    width: 90%;
    padding-bottom: 45%;
  }

  #sub p {
    font-size: 12px;
  }

  #sub .text {
    width: 100%;
    padding: 0 20px;
  }
}