header {
    position: fixed;
    width: 100vw;
    height: 70px;
    z-index: 10;  
}

.show {
    /* display: block; */
    opacity: 1 !important;
}

header .inner {
    transition: opacity 0.7s ease;
    opacity: 0;
    padding: 9px 40px;
    background-color: #09183B;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .logo {
    height: 100%;
}

header .menu {
    height: 30%;
    position: relative;
    cursor: pointer;
}

header .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(19, 19, 18, 0.4);
    /* opacity: 0.40; */
    z-index: 101;
    transition: 1s;
}

header .side-menu {
    position: absolute;
    width: 500px;
    top: 0;
    min-height: 100vh;
    right: -600px;
    background-color: #09183B;
    opacity: 1;
    z-index: 102;
    transition: right 0.5s;
    padding: 48px 40px 38px;
}

header .side-menu.open {
    right: 0;
    /* Slide the menu into view when it is open */
}

header .menu-logo {
    width: 140px;
}

header .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    cursor: pointer;
}

header nav {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 25px;
}

header .link {
    color: white;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Gotham';
    text-decoration: none;
    transition: 0.5s;
}

header .link:hover {
    color: #38877b;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@media screen and (max-width: 728px) {
    header .side-menu {
        width: 100%
    }
}