#banner {
  width: 100%;
  background-image: url("../../images/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  /* height: 1157px; */
  background-position: center;
  background-size: cover;
  min-height: 400px;
}

#banner .blue2 {
  position: absolute;
  top: -6%;
  left: -19%;
  right: 0;
}

#banner .vid {
  position: absolute;
  width: 140px;
  right: 2%;
  top: calc(50% - 70px);
  animation: rotate 8s linear infinite;
  cursor: pointer;
  z-index: 5;
}

#banner .play {
  position: absolute;
  width: 30px;
  right: calc(2% + 50px);
  top: calc(50% - 20px);
  cursor: pointer;
  z-index: 5;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#banner .logo {
  width: 20%;
}

#banner .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#banner .text {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  font-size: 36px;
}

#banner h1 {
  font-family: 'NAMU';
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  line-height: 120%;
  /* or 43px */

  text-transform: uppercase;

  color: #FFFFFF;
}

#banner h2 {
  font-family: 'NAMU';
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  line-height: 120%;
  color: #FFFFFF;
}

#banner hr {
  width: 20%;
  border: 2px solid #38877B;
}

#banner button {
  padding: 15px 30px;
  gap: 10px;
  border: none;
  outline: none;
  background: #38877B;
  border-radius: 10px;
  font-family: 'TT Interfaces';
  font-style: normal;
  font-weight: 500;
  font-size: 0.38em;
  line-height: 120%;
  color: #FFFFFF;
  vertical-align: middle;
}

#banner .fade-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

#banner .fade-in-up {
  opacity: 0;
  transform: translateY(30px);
  animation: fade-in-up 0.3s ease-in forwards;
}

#banner .fade-out-down {
  opacity: 1;
  transform: translateY(0);
  animation: fade-out-down 0.3s ease-out forwards;
}

#banner .iframe-con {
  width: 90%;
  height: 0;
  padding-bottom: 50.625%;
  position: relative;
}

#banner .iframe-con iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-down {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(30px);
  }
}

@media screen and (max-width: 768px) {
  #banner .text {
    font-size: 24px;
  }

  hr {
    display: none;
  }

  #banner .vid {
    width: 100px;
    top: calc(30% - 50px);
  }
  
  #banner .play {
    width: 20px;
    right: calc(2% + 37px);
    top: calc(30% - 15px);
  }
}