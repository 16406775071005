#trim {
    width: 100%;
    padding: 100px 0px;
    position: relative;
}

#trim .inner {
    width: 80%;
    margin-left: 10%;
}

#trim .bg {
    position: absolute;
    top: 500px;
    left: 0;
    width: 50%;
    z-index: -2;
}

#trim h1 {
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #09183B;
}

#trim .gallery-inner p:first-child {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    width: 50%;
    margin: 20px 0;
    color: #A4ABB3;
}

#trim .gallery-inner {
    width: 80%;
    margin-left: 15%;
}

#trim .cards {
    display: flex;
    width: 80%;
    align-items: stretch;
    border: 1px;
}

#trim .carousel {
    width: 80%;
    margin-left: auto;
}

#trim .carousel .car-item {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    position: relative;
}

#trim .carousel .car-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#trim .card {
    position: relative;
    z-index: 1;
    font-size: 1vw;
    width: 33%;
    background-color: white;
    border: 1px solid #ABAEB3;
    padding: 40px 40px 44px;
    /* height: 210px; */
    display: flex;
    align-items: flex-start;
    gap: 5%;
}

#trim .card:first-child {
    align-self: flex-start;
    height: 100%;
    margin-bottom: -100%;
    gap: 3%;
    position: relative;
    z-index: 1;
    flex-direction: column;
    border: none;
    background: radial-gradient(139.26% 150.14% at -50.13% -72.7%, #38877B 0%, #09183B 100%), #09183B;
}

#trim h3 {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 120%;
    color: #09183B;
    margin-bottom: 17px;
}

#trim h5 {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125em;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #09183B;
    margin-bottom: 5px;
}

#trim .card p {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 120%;
    color: #B6BBBA;
}

#trim .card:first-child h3,
#trim .card:first-child h5 {
    color: white;
}

#trim .levitate {
    display: flex;
    margin: 0 auto;
    margin-top: 200px;
    justify-content: space-between;
}

#trim .bg2 {
    position: absolute;
    width: 48%;
    top: 40%;
    right: 0;
    z-index: -1;
}

#trim .left {
    width: 48%;
    display: flex;
    flex-direction: column;
}

#trim .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#trim .right h2 {
    height: 400px;
    padding: 100px 0;
}

#trim .right img {
    width: 100%;
}

#trim .right h2 span {
    display: block;
    font-family: 'NAMU';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 120%;
    text-transform: uppercase;
    color: #09183B;
}

#trim .left>*:nth-child(1) img {
    width: 75%;
    margin-left: 20%;
}

#trim .left img {
    width: 100%;
}

#trim .left>*:nth-child(2) {
    margin-top: -70px;
    margin-left: auto;
}

#trim .tour {
    margin: 140px 0;
    height: 600px;
}

#trim .loading {
    position: relative;
    background-color: #000000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-spin .ant-spin-dot-item {
    background-color: rgb(255, 255, 255);
}

.ant-carousel .slick-dots {
    z-index: 1;
}

@media screen and (max-width: 1460px) {
    #trim .card {
        padding: 30px 30px 30px;
    }
}


@media screen and (max-width: 1200px) {
    #trim .tour {
        height: 400px;
    }

    #trim .gallery-inner>p {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {

    #trim .inner,
    #trim .gallery-inner {
        width: 100%;
        margin: 0;
        /* padding: 0 20px; */
    }
    /* #trim .inner {
        padding: 0 20px;
    } */

    #trim .gallery-inner p:first-child {
        padding: 0 20px;
        width: 100%;
    }

    #trim h1 {
        font-size: 24px;
        padding: 0 20px;
    }

    #trim .gallery {
        width: 100%;
    }

    #trim .cards {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #trim .card {
        font-size: 16px;
        position: static;
        width: 100%;
        flex-direction: column;
        gap: 3%;
    }

    #trim .card:first-child {
        position: static;
        align-self: none;
        margin-bottom: 0;
    }

    #trim .carousel {
        width: 100%;
        margin-top: -10px;
    }

    #trim .levitate {
        flex-direction: column-reverse;
        gap: 0;
    }

    #trim .left {
        width: 100%;
    }

    #trim .right {
        width: 100%;
    }

    #trim .right h2 {
        height: unset;
        padding:  0;
        padding-bottom: 40px;
    }

    #trim .levitate {
        width: 100%;
        padding: 0 20px;
    }

    #trim .left img {
        width: 80%;
    }
}

@media screen and (min-width: 1200px) {
    #trim .gallery-inner {
        width: 80%;
        margin-left: 15%;
    }

    #trim .inner {
        width: 70%;
        margin: 0 auto;
    }
}