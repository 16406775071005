.form-con {
    width: 560px;
}

.form-flex {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.form-con h2 {
    color: #ffffff;
    font-weight: 700;
    font-family: 'K_Gotham Pro';
    text-transform: uppercase;
    font-size: 36px;
    line-height: 1.23;
    text-align: center;
}

.form-con img {
    width: 100%;
}

.form-con p {
    color: #ffffff;
    font-weight: 300;
    font-family: 'K_Gotham Pro';
    font-size: 16px;
    line-height: 1.23;
    text-align: center;
}

form>.text {
    display: flex;
    flex-direction: column;
}

form label {
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    text-transform: lowercase;
    color: #F6F6F6;

    opacity: 0.5;
}

form input[type="text"] {
    border: none;
    outline: none;
    background-color: transparent;
    font-family: 'K_Gotham Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-transform: capitalize;
    color: #FFFFFF;
    border-bottom: 0.5px solid #F6F6F6;
    border-radius: 0;
    margin-top: 5px;
}

form button {
    color: #ffffff;
    border: 2px solid #38877b;
    font-family: 'K_Gotham Pro';
    font-weight: 500;
    width: 100%;
    background-color: transparent;
    height: 54px;
    font-size: 16px;
}

form .arrow {
    display: none;
}

form button:hover .arrow {
    transform: translateX(5px);
    color: #E76B47;
}

form label>input[type="checkbox"] {
    display: none;
}

form label>input[type="checkbox"]+div {
    display: flex;
    align-items: center;
    gap: 10px;
}

form label>input[type="checkbox"]+div::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 14px;
    height: 14px;
    border-radius: 10%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(255, 255, 255);
}

form label>input[type="checkbox"]:checked+*::before {
    content: "✓";
    color: white;
    text-align: center;
}

form input[type="text"]::placeholder {
    text-transform: none;
    font-weight: 300;
    color: white;
    opacity: 0.5;
}

form .arrow {
    margin-left: 10px;
}

form .error {
    height: 15px;
    color: rgb(255, 119, 119);
    font-size: 11px;
    margin-top: 4px;
    opacity: 1;
}

.form-con .inner {
    width: 100%;
    padding: 40px 45px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: #09183b;
}

@media screen and (max-width: 768px) {
    .form-con {
        width: 100%;
        margin: 20px;
    } 

    .form-con h2 {
        font-size: 24px;
    }
}