.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 200px;
    height: 200px; */
    bottom: 3%;
    right: 3%;
    z-index: 10;
}

.circle {
    cursor: pointer;
    width: 60px;
    height: 60px;
    background: #09183b;
    position: relative;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: circle-pulsate infinite ease-in-out 2s;
    border: 1px solid white;
}

.pulse-ring {
    width: 60px;
    height: 60px;
    border: 1px solid #38877b;
    border-radius: 50%;
    position: absolute;
    animation: pulsate infinite ease-in-out 1s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pulse-ring2 {
    width: 60px;
    height: 60px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    animation: pulsate2 infinite ease-in-out 1.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes pulsate {
    0% {
        transform: scale(1, 1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.4, 1.4);
        opacity: 0;
    }
}

@keyframes pulsate2 {
    0% {
        transform: scale(1, 1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.6, 1.6);
        opacity: 0;
    }
}

@keyframes circle-pulsate {
    0% {
        transform: scale(1, 1);
        transform: rotate(-10deg);
    }
    50% {
        transform: scale(1.2, 1.2);
        transform: rotate(10deg);
    }
    100% {
        transform: scale(1, 1);
        transform: rotate(-10deg);
    }
}