.tabs {
    margin-bottom: 60px;
    height: 600px;
}

.tabs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding:30px 0;
    position: relative;
    z-index: 9;
}

.tabs-item {
    width: 100%;
    list-style-type: none;
    text-align: center;
    padding: 0 10px 18px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    font-family: 'K_Gotham Pro';
    font-size: 1em;
    font-weight: 400;
}

.tabs-item.active {
    border-bottom: 1px solid #09183B;
    color: #09183B;
    font-weight: 700;
}

.content {
    display: none;
}

.show-content {
    display: block;
    height: 550px;
}

@media screen and (max-width: 1200px) {
    .show-content {
        display: block;
        height: 350px;
    }
}

@media screen and (max-width: 768px) {
    .tabs-item {
        font-size: 0.75em;
    }
}
